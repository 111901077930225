<template>
  <div class="qy">
    <div class="banner">
      <van-image class="image" :src="require('@/assets/banner.png')"></van-image>
    </div>
    <van-cell-group class="fromeCode" inset>
      <van-form label-width="3.5em" colon>
        <van-field
          styly="margin-bottom:20px"
          name="兑换码"
          label="兑换码"
          placeholder="请输入兑换码"
          maxlength="32"
          :rules="[{ required: true }]"
          v-model="redemptionCode"
          @change="codeBlur(redemptionCode)"
        />
        <div class="code">
          <van-field v-model="code" label="验证码" placeholder="请输入验证码">
          </van-field>
          <div @click="refreshCode">
            <Code :identifyCode="identifyCode"></Code>
          </div>
        </div>
        <div style="margin: 16px 0">
          <van-button
            color="linear-gradient(to right, #e77638, #fb8777)"
            style="width: 100%"
            round
            @click="exchange"
            >确认兑换</van-button
          >
        </div>
      </van-form>
      <van-divider>使用说明</van-divider>
      <van-cell
        class="explain"
        value="1、输入收到的兑换码，填写验证码，点击确定进行校验，校验过后进入会员兑换页面:"
      />
      <van-cell
        class="explain"
        value="2、输入要充值的账号进行充值，充值成功后登录对应平台查看权益到账情况。"
      />
      <van-cell
        class="explain"
        value="3、兑换前请仔细核对充值账号，本产品属于虚拟产品，兑换成功后无法退换。"
      />
      <van-cell
        class="explain"
        value="4、充值到账时间一般在24小时以内，若长时间未到账请联系客服；"
      />
      <van-cell class="explain">
        <p>5、客服电话：<a href="tel:4006-659-619">4006-659-619</a>（09:00-18:00）。</p>
      </van-cell>
    </van-cell-group>
  </div>
</template>
<script>
import Code from "../components/code";
import { api_couponVerfiy } from "../api/clientOrder";
import { sessionStorage } from "../utils/storage";
export default {
  components: {
    Code,
  },
  data() {
    return {
      code: "",
      redemptionCode: "",
      identifyCodes: "1234567890abcdefjhijklinopqrsduvwxyz",
      identifyCode: "",
      // 校验
      rules: {
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
    };
  },
  created() {},
  methods: {
    refreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
    },
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes[
          this.randomNum(0, this.identifyCodes.length)
        ];
      }
    },
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    codeBlur(val) {
      const validCodeLengths = [12, 16, 20, 24, 28, 32];

      if (!validCodeLengths.includes(val.length)) {
        this.$toast.fail("兑换码长度错误，请输入正确的兑换码");
      }
    },
    exchange() {
      // 验证码检验
      const validCodeLengths = [12, 16, 20, 24, 28, 32];
      const redemptionCodeLength = this.redemptionCode.length;
      if (!validCodeLengths.includes(redemptionCodeLength)) {
        this.$toast.fail("兑换码长度错误，请输入正确的兑换码");
        return;
      }
      const lowercaseCode = this.code.toLowerCase();
      const lowercaseIdentifyCode = this.identifyCode.toLowerCase();
      if (lowercaseCode !== lowercaseIdentifyCode) {
        this.$toast.fail("请输入正确验证码");
        return;
      }
      const params = {
        coupon: this.redemptionCode,
      };
      api_couponVerfiy(params).then((res) => {
        if (res.data.code === 0) {
          sessionStorage.set("goods", res.data.data.goods);
          sessionStorage.set("token", res.data.data.token);
          sessionStorage.set("coupon", this.redemptionCode);
          window.location.href = res.data.data.url;
        } else {
          this.$dialog
            .confirm({
              title: "错误！",
              message: res.data.msg,
            })
            .then(() => {
              sessionStorage.clear();
            })
            .catch(() => {
              // on cancel
            });
        }
      });
    },
    
    // exchange() {
    //   // 验证码检验
    //   let arry = [12, 16, 20, 24, 28, 32];
    //   if (!arry.includes(this.redemptionCode.length)) {
    //     this.$toast.fail("兑换码长度错误，请输入正确的兑换码");
    //   } else {
    //     if (this.code.toLowerCase() !== this.identifyCode.toLowerCase()) {
    //       this.$toast.fail("请输入正确验证码");
    //     } else {
    //       if (this.redemptionCode != "") {
    //         const params = {
    //           coupon: this.redemptionCode,
    //         };
    //         api_couponVerfiy(params).then((res) => {
    //           if (res.data.code == 0) {
    //             sessionStorage.set("goods", res.data.data.goods);
    //             sessionStorage.set("token", res.data.data.token);
    //             sessionStorage.set("coupon", this.redemptionCode);
    //             window.location.href = res.data.data.url;
    //           } else {
    //             this.$dialog
    //               .confirm({
    //                 title: "错误！",
    //                 message: res.data.msg,
    //               })
    //               .then(() => {
    //                 sessionStorage.clear();
    //               })
    //               .catch(() => {
    //                 // on cancel
    //               });
    //           }
    //         });
    //       } else {
    //         this.$toast.fail("请填写兑换码");
    //       }
    //     }
    //   }
    // },
  },
  mounted() {
    this.identifyCode = "";
    this.makeCode(this.identifyCodes, 4);
  },
};
</script>

<style scoped>
.qy {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #efefef;
  height: 100%;
  overflow: auto;
  position: relative;
}
.banner {
  background: linear-gradient(to bottom, #fb8777, #e77638);
  height: 22em;
  width: 100%;
  border-radius: 0 0 6em 6em;
  position: relative;
  display: flex;
  justify-content: center;
}
.fromeCode {
  position: absolute;
  background: #fff;
  padding: 4em 1em 1em 1em;
  overflow: scroll;
  top: 17em;
  height: auto;
}

.image {
  position: absolute;
  bottom: 40px;
  z-index: 999;
}
::v-deep .van-form .van-cell {
  border-radius: 2em;
  background: #f8f8f8;
}
.code {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
::v-deep .code .van-cell {
  width: 70%;
}
::v-deep .van-cell__title {
  color: #fb8777 !important;
  font-size: 16px;
  font-weight: 600;
}
.van-button__text {
  font-size: 18px;
  font-weight: 500;
}
.van-divider {
  font-size: 18px;
  color: #333;
  font-weight: 500;
}
.explain {
  padding: 3px 10px;
}
.van-divider::after,
.van-divider::before {
  color: #333 !important;
}
.van-cell::after {
  border: 0;
}
</style>
